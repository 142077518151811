var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M10.964 10.964c-.785.811-.843 1.822-.91 4.036 2.064 0 3.489-.007 4.225-.721.714-.736.721-2.229.721-4.225-2.214.071-3.225.125-4.036.91ZM5 10.054c0 1.996.007 3.489.721 4.225.736.714 2.161.721 4.225.721-.071-2.214-.125-3.225-.91-4.036-.811-.785-1.822-.843-4.036-.91ZM9.946 5c-2.06 0-3.489.007-4.225.721C5.007 6.457 5 7.95 5 9.946c2.214-.071 3.225-.125 4.036-.91.785-.811.843-1.822.91-4.036Zm1.018 4.036c-.785-.811-.843-1.822-.91-4.036 2.064 0 3.489.007 4.225.721.714.736.721 2.229.721 4.225-2.214-.071-3.225-.125-4.036-.91Z",
      "fill": "#fff"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "d": "M15 10.054v-.108c-2.214-.071-3.225-.125-4.036-.91-.785-.811-.843-1.822-.91-4.036h-.108c-.071 2.214-.125 3.225-.91 4.036-.811.785-1.822.843-4.036.91v.108c2.214.071 3.225.125 4.036.91.785.811.843 1.822.91 4.036h.108c.071-2.214.125-3.225.91-4.036.811-.785 1.822-.843 4.036-.91Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }